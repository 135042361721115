/**
 * @fileoverview API for disciplines
 */

import axiosClient from "../../middleware/axios.middelware";

const URL_HCSERVICES = "/orchestrator/hcservices";

const convertDisciplineOption = ({
    _id, // ObjectId in mongoDb
    id, // Id for healthcare service
    active,
    name,
    organizationId,
    practitionerIds,
    locations,
    updatedBy,
    updatedAt,
    updatedByLastName,
    updatedByFirstName
}) => ({
    id,
    _id,
    name,
    isDeactivated: !active,
    organizationId,
    practitionerIds,
    locations: locations || [],
    updatedBy,
    updatedAt,
    updatedByLastName,
    updatedByFirstName
});

/**
 * fetch disciplines for select options
 * @param {number} organizationId   the organizationId
 * @param {Boolean} includeSlotRooms
 * @return {Promise}
 */
function fetchDisciplineOptions(organizationId, includeSlotRooms) {
    const params = {
        organizationId,
        includeSlotLocations: Boolean(includeSlotRooms)
    };

    return axiosClient.get(URL_HCSERVICES, {params});
}

/**
 * save discipline
 * @param {Object} params  discipline object
 * @return {Promise}
 */
function saveDisciplineAPI(params) {
    const body = {...params};
    delete body.id;
    return axiosClient.patch(`${URL_HCSERVICES}/${params.id}`, body); // @TODO #14783: to be adjusted, reduce data send in body. send changed data only?
}

export {convertDisciplineOption, fetchDisciplineOptions, saveDisciplineAPI};
