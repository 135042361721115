/**
 * @fileoverview redux action types for disciplines
 */

const ActionTypes = {
    LOAD_REQUEST: "disciplines/LOAD_DISCIPLINES_REQUEST",
    LOAD_SUCCESS: "disciplines/LOAD_DISCIPLINES_SUCCESS",
    LOAD_FAILURE: "disciplines/LOAD_DISCIPLINES_FAILURE",
    LOAD_OPTIONS_REQUEST: "disciplines/LOAD_OPTIONS_REQUEST",
    LOAD_OPTIONS_SUCCESS: "disciplines/LOAD_OPTIONS_SUCCESS",
    LOAD_OPTIONS_FAILURE: "disciplines/LOAD_OPTIONS_FAILURE",
    SAVE_REQUEST: "disciplines/SAVE_DISCIPLINES_REQUEST",
    SAVE_SUCCESS: "disciplines/SAVE_DISCIPLINES_SUCCESS",
    SAVE_FAILURE: "disciplines/SAVE_DISCIPLINES_FAILURE"
};

export default ActionTypes;
