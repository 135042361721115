/**
 * DisciplineSelect
 *
 * Optional props "selectDisciplines" can be used to pass certain list of disciplines
 *
 * usage:
 * <code>
 *     const {classes} = useStyles();
 *     const { discipline, setDiscipline } = useState("");
 *
 *     const handleChangeDiscipline = (e) => {
 *         setDiscipline(e.target.value);
 *     };
 *
 *     <DisciplineSelect onlyActive value={discipline} onSelect={handleChangeDiscipline} classes={classes}/>
 * </code>
 */

/**
 * SurgeonsMultiSelect
 *
 * MultiSelect for surgeons (surgeons list is based on discipline (empIds))
 * Optional props "selectSurgeons" can be used to pass certain list of surgeons
 *
 * usage:
 * <code>
 *     const {classes} = useStyles();
 *     const {surgeons, setSurgeons} = useState([]);
 *
 *     const handleChangeSurgeon = (e) => {
 *         setSelectedSurgeons(e.target.value);
 *     };
 *
 *     <SurgeonsMultiSelect  values={surgeons} onSelect={handleChangeSurgeon} classes={classes}/>
 * </code>
 */

export {default as DisciplineSelect} from "./containers/discipline_select_container";
export {default as SurgeonsMultiSelect} from "./containers/surgeons_multiselect_container";
export {default as disciplinesReducer} from "./disciplines_reducer";
export {saveDisciplineAction, loadDisciplineOptionsAction} from "./disciplines_actions";
export {selectOptions, selectStatus, selectError, selectSaveStatus, selectEmpIdsOfDisciplines} from "./disciplines_selectors";
