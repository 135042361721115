/**
 * @fileoverview redux reducer for disciplines
 */

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./disciplines_action_types";

const initialState = {
    options: [],
    status: STATUS.IDLE,
    saveStatus: STATUS.IDLE,
    error: null
};

const handleLoadRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    disciplines: payload
});

const handleLoadOptionsSuccess = (state, {payload}) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    options: payload
});

const handleSaveRequest = (state) => ({
    ...state,
    saveStatus: STATUS.PENDING,
    error: null
});

const handleSaveFailure = (state, {error}) => ({
    ...state,
    saveStatus: STATUS.REJECTED,
    error
});

const handleSaveSuccess = (state) => ({
    ...state,
    saveStatus: STATUS.RESOLVED,
    error: null
});

const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,
    [ActionTypes.LOAD_OPTIONS_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_OPTIONS_SUCCESS]: handleLoadOptionsSuccess,
    [ActionTypes.LOAD_OPTIONS_FAILURE]: handleLoadFailure,
    [ActionTypes.SAVE_REQUEST]: handleSaveRequest,
    [ActionTypes.SAVE_SUCCESS]: handleSaveSuccess,
    [ActionTypes.SAVE_FAILURE]: handleSaveFailure
};

export default createReducer(initialState, handlers);
export {STATUS};
