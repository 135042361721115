// @ts-check
/**
 * @fileoverview discipline select input
 */
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./discipline_select.styles";

/**
 * @typedef StylesDisciplineSelect
 * @type {Object}
 * @property {string} [placeholder]
 * @property {string} [input]
 * @property {string} [menu]
 * @property {string} [menuWrapper]
 */
/**
 * Render DisciplineSelect component
 * @param {Object} props
 * @param {StylesDisciplineSelect} [props.styles]
 * @param {Boolean} [props.disabled]
 * @param {Array<{key: String, text: String}>} props.disciplines
 * @param {String} props.value
 * @param {Function} props.onSelect
 * @param {Boolean} [props.required]
 * @param {Boolean} [props.hideLabel]
 * @param {Boolean} [props.includeAll]
 * @param {Boolean} [props.fullWidth]
 * @return {React.ReactElement}
 */
const DisciplineSelect = ({styles, disabled, disciplines, value, onSelect, required, hideLabel, includeAll, fullWidth}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();

    return (
        <div data-testid="disciplineDropdownForm">
            <FormControl
                classes={{
                    root: cx({
                        [classes.fullWidth]: fullWidth
                    })
                }}
                required={required}
                variant="standard"
            >
                {!hideLabel && (
                    <InputLabel id="select-discipline-label" shrink>
                        {t("App.disciplineLabel")}
                    </InputLabel>
                )}
                <Select
                    disabled={disabled}
                    displayEmpty
                    inputProps={{
                        className: cx(classes.input, styles.input)
                    }}
                    MenuProps={{
                        classes: {
                            paper: cx(classes.menuWrapper, styles.menuWrapper)
                        },
                        variant: "menu"
                    }}
                    renderValue={(value) => {
                        if (!value) {
                            return <div className={cx(classes.placeholder, styles.placeholder)}>{t("App.disciplinePlaceholder")}</div>;
                        } else if (value === "all") {
                            return t("App.allDisciplines");
                        }
                        const item = disciplines.find((d) => d.key === value);
                        return (item && item.text) || value;
                    }}
                    value={value}
                    variant="standard"
                    onChange={(e) => onSelect(e)}
                >
                    {disciplines.length === 0 && (
                        <MenuItem disabled value="">
                            <div className={cx(classes.placeholder, styles.placeholder)}>{t("App.noDisciplines")}</div>
                        </MenuItem>
                    )}
                    {disciplines.length !== 0 && includeAll && (
                        <MenuItem className={cx(classes.menu, styles.menu)} key="all" value="all">
                            {t("App.allDisciplines")}
                        </MenuItem>
                    )}
                    {disciplines.map((item) => (
                        <MenuItem className={cx(classes.menu, styles.menu)} key={item.key} value={item.key}>
                            {item.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

DisciplineSelect.propTypes = {
    styles: PropTypes.shape({
        placeholder: PropTypes.string,
        input: PropTypes.string,
        menu: PropTypes.string,
        menuWrapper: PropTypes.string
    }),
    disabled: PropTypes.bool,
    disciplines: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    value: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    required: PropTypes.bool,
    hideLabel: PropTypes.bool,
    includeAll: PropTypes.bool,
    fullWidth: PropTypes.bool
};

DisciplineSelect.defaultProps = {
    styles: {}
};

export default DisciplineSelect;
