// @ts-check
/**
 * @fileoverview redux selectors for disciplines
 */

import uniq from "lodash/uniq";
import {createSelector} from "reselect";

const selectDisciplinesState = (state) => state.disciplines;

const selectError = (state) => selectDisciplinesState(state).error;

const selectStatus = (state) => selectDisciplinesState(state).status;

/**
 * Selector for the options in disciplines
 * @param {*} state
 * @return {{id: string, locations: string[], name: string, organizationId: (string|undefined),
 *  practitionerIds: string[], updatedAt: string, updatedBy: {email: string},
 *  updatedByFirstName: (string|undefined), updatedByLastName: (string|undefined), _id: string, isDeactivated: boolean }[]} The array of options
 */
const selectOptions = (state) => selectDisciplinesState(state).options;

const selectDisciplineOptions = createSelector(selectOptions, (disciplines) =>
    disciplines
        .map(({id, name, isDeactivated, practitionerIds}) => ({key: id, text: name, isDeactivated, practitionerIds}))
        .sort((a, b) => a.text.localeCompare(b.text))
);

const selectActiveDisciplineIds = createSelector(selectOptions, (disciplines) =>
    disciplines
        .filter(({isDeactivated}) => !isDeactivated)
        .map(({id}) => id)
        .sort()
);

const selectSaveStatus = (state) => selectDisciplinesState(state).saveStatus;

const selectEmpIdsOfDisciplines = (state, disciplines) => {
    let empIds = [];
    selectDisciplinesState(state).options.forEach((item) => {
        if (disciplines.includes(item.id)) {
            empIds = empIds.concat(item.practitionerIds);
        }
    });
    return uniq(empIds);
};

const selectDisciplinesIdsAndName = createSelector(selectOptions, (optionsState) => optionsState.map(({id, name}) => ({id, name})));

/**
 * Selector for all practitioners Ids
 *
 * @param {Object} state
 * @return {Array<string>} The list of practitioners ids
 */
const selectAllPractitionerIds = createSelector(selectDisciplinesState, (disciplineState) => {
    let empIds = [];
    disciplineState.options.forEach((item) => (empIds = empIds.concat(item.practitionerIds)));
    return uniq(empIds);
});

export {
    selectError,
    selectStatus,
    selectDisciplineOptions,
    selectSaveStatus,
    selectEmpIdsOfDisciplines,
    selectOptions,
    selectActiveDisciplineIds,
    selectDisciplinesIdsAndName,
    selectAllPractitionerIds
};
