import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    input: {
        display: "flex",
        alignItems: "center",
        minWidth: "6rem"
    },
    inputWrapper: {
        "width": "12.5rem",
        "marginLeft": theme.spacing("m"),
        "& svg": {
            fontSize: theme.spacing("m")
        }
    },
    closeIcon: {
        "fontSize": theme.spacing("m"),
        "marginLeft": theme.spacing("s"),
        "borderRadius": theme.spacing("s"),
        "border": `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            background: theme.palette.grey[100]
        }
    },
    placeholder: {
        color: theme.palette.grey[500],
        fontSize: "0.875rem"
    },
    menuWrapper: {
        maxHeight: "24rem"
    },
    menu: {
        "fontSize": "0.875rem",
        "padding": "0 0.625rem",
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: theme.spacing("m")
        }
    },
    checkbox: {
        color: theme.palette.primary.main
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));
