import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    input: {
        width: "10rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary
    },
    menuWrapper: {
        maxHeight: "21rem"
    },
    menu: {
        fontSize: "0.875rem"
    },
    placeholder: {
        color: theme.palette.grey[500],
        fontSize: "0.875rem"
    },
    fullWidth: {
        width: "100%"
    }
}));
