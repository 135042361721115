// @ts-check
/**
 * @fileoverview surgeons multi select input
 */

import {Close, Search} from "@mui/icons-material";
import {Checkbox, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import sortArrayOfObjectsByName from "../../../utils/sort_array_of_objects_by_name";
import {selectFullNamesArray} from "../../private_data/private_data_selectors";
import useStyles from "./surgeons_multiselect.styles";

const SurgeonsMultiSelect = ({styles, surgeons, values, onSelect, disabled}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();

    // redux
    const practitionerNamesArray = useSelector(selectFullNamesArray({ids: surgeons || [], type: "practitioner"}));
    const sortedSurgeons = practitionerNamesArray.sort(sortArrayOfObjectsByName);
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const [open, setOpen] = useState(false);
    const [filterText, setFilterText] = useState("");

    /**
     * Set input text in filterText and value of input
     * @param {object} e
     */
    const handleFilterText = (e) => {
        setFilterText(e.target.value);
        inputRef.current.value = e.target.value;
    };

    /**
     * Reset all selected items
     */
    const handleResetClick = () => {
        onSelect([]);
    };

    /**
     * Handler for change selected items
     * Only set when menu items except text field (first item) are clicked
     * @param {object} e
     * @param {object} child - child.key
     */
    const handleChange = (e, child) => {
        if (child.key !== ".0") {
            onSelect(e.target.value);
        }
    };

    /**
     * Handler to keep focus on text field if the text field is clicked
     * By default, the focus is set on the menu item which matches with the entered key
     * @param {object} e
     */
    const handleKeyPress = (e) => {
        if (e.key) {
            inputRef.current.focus();
        }
    };

    /**
     * Handler to reset filterText
     * Since TextField is uncontrolled, both state and input value are set.
     */
    const handleResetFilterText = () => {
        setFilterText("");
        inputRef.current.value = "";
    };

    /**
     * stop firing MenuItem event if TextField is clicked/entered
     * @param {object} e - event object
     */
    const stopPropagation = (e) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
                break;
            default:
                e.stopPropagation();
        }
    };

    return (
        <div>
            <FormControl variant="standard">
                <InputLabel id="select-surgeon-label" shrink>
                    {t("App.surgeonsLabel")}
                </InputLabel>
                <Select
                    disabled={disabled}
                    displayEmpty
                    inputProps={{
                        className: cx(classes.input, {
                            [styles.input]: styles.input
                        })
                    }}
                    MenuProps={{
                        classes: {
                            paper: cx(classes.menuWrapper, styles.menuWrapper)
                        },
                        variant: "menu",
                        disableAutoFocus: true,
                        onKeyPress: handleKeyPress
                    }}
                    multiple
                    open={open}
                    renderValue={(selected) => {
                        if (!selected.length) {
                            return (
                                <div
                                    className={cx(classes.placeholder, {
                                        [styles.placeholder]: styles && styles.placeholder
                                    })}
                                >
                                    {t("App.surgeonsPlaceholder")}
                                </div>
                            );
                        }
                        let firstSelectedName = null;
                        if (selected.length >= 1) {
                            firstSelectedName = sortedSurgeons.find((el) => el.id === selected[0]);
                        }
                        return selected.length === 1 ? (
                            <Fragment>
                                <div className={classes.text}>{selected[0] === "all" ? t("App.all") : firstSelectedName?.name}</div>
                                {!selected.includes("all") && (
                                    <Close
                                        className={cx(classes.closeIcon, {
                                            [styles.closeIcon]: styles && styles.closeIcon
                                        })}
                                        id="resetSurgeons"
                                        onClick={handleResetClick}
                                    />
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={classes.text}>
                                    {firstSelectedName?.name}&nbsp;{`(+${selected.length - 1})`}
                                </div>
                                <Close
                                    className={cx(classes.closeIcon, {
                                        [styles.closeIcon]: styles && styles.closeIcon
                                    })}
                                    id="resetSurgeons"
                                    onClick={handleResetClick}
                                />
                            </Fragment>
                        );
                    }}
                    value={values}
                    variant="standard"
                    onChange={handleChange}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onOpen={(e) => {
                        // If reset icon is clicked, do not open menu.
                        if (e.target.id !== "resetSurgeons" && !e.target.closest("path")) {
                            setOpen(true);
                        }
                    }}
                >
                    <TextField
                        className={cx({
                            [classes.inputWrapper]: !styles.inputWrapper,
                            [styles.inputWrapper]: Boolean(styles.inputWrapper)
                        })}
                        defaultValue={filterText}
                        // uncontrolled in order to enable reset text
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="primary" fontSize="small" />
                                </InputAdornment>
                            ),
                            endAdornment: filterText && (
                                <InputAdornment position="end">
                                    <Close fontSize="small" style={{cursor: "pointer"}} onClick={handleResetFilterText} />
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        inputProps={{
                            className: cx({
                                [classes.input]: !styles.input,
                                [styles.input]: Boolean(styles.input)
                            })
                        }}
                        inputRef={inputRef}
                        name="searchField"
                        placeholder={t("App.searchSurgeons")}
                        variant="standard"
                        onChange={handleFilterText}
                        onKeyDown={stopPropagation}
                    />
                    {sortedSurgeons
                        .filter((emp) => emp.name.toLowerCase().includes(filterText.toLowerCase()))
                        .map((item) => (
                            <MenuItem className={cx(classes.menu, styles.menu)} disabled={disabled} key={item.id} value={item.id}>
                                <Checkbox checked={values.indexOf(item.id) > -1} className={classes.checkbox} size="small" />
                                {item.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
};

SurgeonsMultiSelect.propTypes = {
    styles: PropTypes.shape({
        inputWrapper: PropTypes.string,
        input: PropTypes.string,
        closeIcon: PropTypes.string,
        menuWrapper: PropTypes.string,
        menu: PropTypes.string,
        placeholder: PropTypes.string
    }),
    surgeons: PropTypes.arrayOf(PropTypes.string).isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

SurgeonsMultiSelect.defaultProps = {
    styles: {}
};

export default SurgeonsMultiSelect;
