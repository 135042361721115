/**
 * @fileoverview redux container for discipline selector
 */

import PropTypes from "prop-types";
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import DisciplineSelect from "../components/discipline_select";
import DisciplinesMultiSelect from "../components/disciplines_multiselect";
import {loadDisciplineOptionsAction} from "../disciplines_actions";
import {selectDisciplineOptions} from "../disciplines_selectors";

/**
 * DisciplineSelectContainer
 */
class DisciplineSelectContainer extends Component {
    /**
     * lifecycle method
     */
    componentDidMount() {
        const {organizationId, loadDisciplines} = this.props;

        loadDisciplines(organizationId);
    }

    /**
     * get options for the dropdown
     * @return {Array}
     */
    getOptions() {
        const {disciplineOptions, onlyActive} = this.props;

        return onlyActive ? disciplineOptions.filter(({isDeactivated}) => !isDeactivated) : disciplineOptions;
    }

    /**
     * render
     * @return {jsx}
     */
    render() {
        const {value, values, onSelect, styles, selectDisciplines, multiple, required, hideLabel, disabled, includeAll, fullWidth, t} =
            this.props;
        const disciplines = selectDisciplines ? selectDisciplines : this.getOptions();

        // Overwrite with translations
        const disciplinesTranslated = disciplines
            .map((el) => ({key: el.key, text: t([`HealthcareService.${el.key}`, el.text])}))
            .sort((a, b) => a?.text.localeCompare(b?.text, "de", {numeric: true, sensitivity: "base"}));

        if (multiple) {
            return (
                <DisciplinesMultiSelect
                    disabled={disabled}
                    disciplines={disciplinesTranslated}
                    fullWidth={fullWidth}
                    hideLabel={hideLabel}
                    includeAll={includeAll}
                    required={required}
                    styles={styles}
                    values={values}
                    onSelect={onSelect}
                />
            );
        }
        return (
            <DisciplineSelect
                disabled={disabled}
                disciplines={disciplinesTranslated}
                fullWidth={fullWidth}
                hideLabel={hideLabel}
                includeAll={includeAll}
                required={required}
                styles={styles}
                value={value}
                onSelect={onSelect}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    organizationId: selectCurrentOrganizationId,
    disciplineOptions: selectDisciplineOptions
});

const mapDispatchProps = {
    loadDisciplines: loadDisciplineOptionsAction
};

DisciplineSelectContainer.propTypes = {
    organizationId: PropTypes.string.isRequired,
    disciplineOptions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ),
    onlyActive: PropTypes.bool,
    value: PropTypes.string, // single select
    values: PropTypes.arrayOf(PropTypes.string), // multiselect
    loadDisciplines: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    styles: PropTypes.shape({
        placeholder: PropTypes.string,
        input: PropTypes.string,
        menu: PropTypes.string
    }),
    selectDisciplines: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ),
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    includeAll: PropTypes.bool,
    fullWidth: PropTypes.bool,
    t: PropTypes.func.isRequired
};

DisciplineSelectContainer.defaultProps = {
    onlyActive: true
};

export default withTranslation()(connect(mapStateToProps, mapDispatchProps)(DisciplineSelectContainer));
