/**
 * @fileoverview redux actions for disciplines
 */

import {authUserFailureAction} from "../../redux/actions/index";
import {loadNamesAction} from "../private_data/private_data_actions";
import ActionTypes from "./disciplines_action_types";
import {convertDisciplineOption, fetchDisciplineOptions, saveDisciplineAPI} from "./disciplines_api";

const loadDisciplineOptionsRequestAction = () => ({
    type: ActionTypes.LOAD_OPTIONS_REQUEST
});

const loadDisciplineOptionsSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_OPTIONS_SUCCESS,
    payload
});

const loadDisciplineOptionsFailureAction = (error) => ({
    type: ActionTypes.LOAD_OPTIONS_FAILURE,
    error
});

/**
 * load disciplines as options for selects
 * @param {string} organizationId   the organizationId
 * @param {Boolean} [includeSlotRooms]
 * @return {AnyAction}
 */
function loadDisciplineOptionsAction(organizationId, includeSlotRooms) {
    return function (dispatch) {
        dispatch(loadDisciplineOptionsRequestAction());

        fetchDisciplineOptions(organizationId, includeSlotRooms)
            .then(({data}) => {
                const options = data.data || [];
                const disciplineOptions = options.map(convertDisciplineOption);
                dispatch(loadDisciplineOptionsSuccessAction(disciplineOptions));

                // Load private names
                const practitionerIds = disciplineOptions.map((option) => option.practitionerIds).flat();
                dispatch(loadNamesAction(organizationId, "practitioner", practitionerIds, false));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch disciplines error"}));
                } else {
                    dispatch(loadDisciplineOptionsFailureAction(error.message));
                }
            });
    };
}

const saveDisciplineRequestAction = () => ({
    type: ActionTypes.SAVE_REQUEST
});

const saveDisciplineSuccessAction = (payload) => ({
    type: ActionTypes.SAVE_SUCCESS,
    payload
});

const saveDisciplineFailureAction = (error) => ({
    type: ActionTypes.SAVE_FAILURE,
    error
});

/**
 * save discipline
 * @param {Object} params
 * @param {String} type "add" or "modify"
 * @return {function}
 */
function saveDisciplineAction(params, type) {
    return function (dispatch) {
        dispatch(saveDisciplineRequestAction());

        saveDisciplineAPI(params, type)
            .then(() => {
                dispatch(saveDisciplineSuccessAction());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "save discipline error"}));
                } else {
                    dispatch(saveDisciplineFailureAction(error));
                }
            });
    };
}

export {loadDisciplineOptionsAction, saveDisciplineAction};
