// @ts-check
/**
 * @fileoverview discipline select input
 */
import {Close} from "@mui/icons-material";
import {Checkbox, Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./disciplines_multiselect.styles";

/**
 * @typedef StylesDisciplinesMultiSelect
 * @type {Object}
 * @property {String} [placeholder]
 * @property {String} [input]
 * @property {String} [menu]
 * @property {String} [menuWrapper]
 * @property {String} [closeIcon]
 */
/**
 * Render DisciplineSelect component
 * @param {Object} props
 * @param {StylesDisciplinesMultiSelect} [props.styles]
 * @param {Boolean} [props.disabled]
 * @param {Array<{key: String, text: String}>} props.disciplines
 * @param {Array<String>} props.values
 * @param {Function} props.onSelect
 * @param {Boolean} [props.required]
 * @param {Boolean} [props.hideLabel]
 * @param {Boolean} [props.includeAll]
 * @param {Boolean} [props.fullWidth]
 * @return {React.ReactElement}
 */
const DisciplinesMultiSelect = ({styles, disciplines, values, onSelect, required, hideLabel, disabled, includeAll, fullWidth}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const [open, setOpen] = useState(false);

    const handleResetClick = () => {
        onSelect({target: {value: []}});
    };
    return (
        <div data-testid="emergencyDisciplineDropdownForm">
            <FormControl
                classes={{
                    root: cx({
                        [classes.fullWidth]: fullWidth
                    })
                }}
                required={required}
                variant="standard"
            >
                {!hideLabel && (
                    <InputLabel id="select-discipline-label" shrink>
                        {t("App.disciplinesLabel")}
                    </InputLabel>
                )}
                <Select
                    disabled={disabled}
                    displayEmpty
                    inputProps={{
                        className: cx(classes.input, styles.input)
                    }}
                    MenuProps={{
                        classes: {
                            paper: cx(classes.menuWrapper, styles.menuWrapper)
                        },
                        variant: "menu"
                    }}
                    multiple
                    open={open}
                    renderValue={(/** @type {Array<String>} */ selected) => {
                        if (!selected.length) {
                            return <div className={cx(classes.placeholder, styles.placeholder)}>{t("App.disciplinesPlaceholder")}</div>;
                        }
                        const firstSelected = disciplines.find((d) => d.key === selected[0]);
                        const firstItem = selected[0] === "all" ? t("App.all") : firstSelected?.text || selected[0];
                        return selected.length === 1 ? (
                            <Fragment>
                                <div className={classes.text} title={firstItem}>
                                    {firstItem}
                                </div>
                                {!selected.includes("all") && (
                                    <Close
                                        className={cx(classes.closeIcon, styles.closeIcon)}
                                        id="resetDisciplines"
                                        onClick={handleResetClick}
                                    />
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={classes.text} title={firstSelected?.text + `(+${selected.length - 1})`}>
                                    {firstSelected?.text}
                                    &nbsp;
                                    {`(+${selected.length - 1})`}
                                </div>
                                <Close
                                    className={cx(classes.closeIcon, styles.closeIcon)}
                                    id="resetDisciplines"
                                    onClick={handleResetClick}
                                />
                            </Fragment>
                        );
                    }}
                    value={values}
                    variant="standard"
                    onChange={(e) => onSelect(e)}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onOpen={(/** @type {React.ChangeEvent} */ e) => {
                        // If reset icon is clicked, do not open menu.
                        if (e.target.id !== "resetDisciplines" && !e.target.closest("path")) {
                            setOpen(true);
                        }
                    }}
                >
                    {disciplines.length === 0 && (
                        <MenuItem disabled value="">
                            <div className={cx(classes.placeholder, styles.placeholder)}>{t("App.noDisciplines")}</div>
                        </MenuItem>
                    )}
                    {includeAll && (
                        <MenuItem className={cx(classes.menu, styles.menu)} disabled={disabled} key="all" value="all">
                            <Checkbox checked={values.indexOf("all") > -1} className={classes.checkbox} size="small" />
                            {t("TimeslotsPage.allDisciplines")}
                        </MenuItem>
                    )}
                    {includeAll && <Divider key="divider" />}
                    {disciplines.map((item) => (
                        <MenuItem className={cx(classes.menu, styles.menu)} disabled={disabled} key={item.key} value={item.key}>
                            <Checkbox checked={values.indexOf(item.key) > -1} className={classes.checkbox} size="small" />
                            {item.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

DisciplinesMultiSelect.propTypes = {
    styles: PropTypes.shape({
        placeholder: PropTypes.string,
        input: PropTypes.string,
        menu: PropTypes.string,
        menuWrapper: PropTypes.string,
        closeIcon: PropTypes.string
    }),
    disciplines: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    required: PropTypes.bool,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    includeAll: PropTypes.bool,
    fullWidth: PropTypes.bool
};

DisciplinesMultiSelect.defaultProps = {
    styles: {}
};

export default DisciplinesMultiSelect;
